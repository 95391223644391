import React from 'react';
import BasicLayout from 'src/components/layouts/BasicLayout';
import SEO from 'src/components/SEO';
import { Paragraph, H3, A } from 'src/DesignSystem';
import littleDevilsLogo from 'src/images/little-devils-logo.png';
import LittleDevilsPdf from 'src/documents/little-devils-1-2.pdf';
import Tag from 'src/components/Tag';
import {
  faCodeBranch,
  faUser,
  faClock,
  faFile,
} from '@fortawesome/pro-solid-svg-icons';
import CenteredButton from 'src/components/CenteredButton';

const PAGE_DESCRIPTION = `You are the naughty children of an estranged billionaire, living in a
          fabulous mansion and bored out of your minds. So bored, in fact, that
          you made a deal with a Devil in order to gain the power to torment
          your nanny and sow chaos. And all you had to give up in return was
          your humanity!`;

export default function NeonSun(): JSX.Element {
  return (
    <BasicLayout>
      <SEO title="Little Devils" description={PAGE_DESCRIPTION} />
      <div className="max-w-4xl p-4 mx-auto text-gray-900 mb-8">
        <img
          className="mx-auto mb-4"
          src={littleDevilsLogo}
          alt="Little Devils Logo"
        />
        <Tag color="orange" icon={faClock}>
          1 session
        </Tag>
        <Tag color="purple" icon={faUser}>
          3-5 + GM
        </Tag>
        <Tag color="blue" icon={faFile}>
          1 page RPG
        </Tag>
        <Tag color="teal" icon={faCodeBranch}>
          v1.2
        </Tag>
        <CenteredButton href={LittleDevilsPdf} rel="noopenener" target="blank">
          Download PDF
        </CenteredButton>
        <Paragraph>
          You are the naughty children of an estranged billionaire, living in a
          fabulous mansion and bored out of your minds. So bored, in fact, that
          you made a deal with a Devil in order to gain the power to torment
          your nanny and sow chaos. And all you had to give up in return was
          your humanity!
        </Paragraph>
        <H3>The Game</H3>
        <Paragraph>
          Little Devils is a 1-page RPG about causing chaos and letting out your
          inner devil. It uses a unique d6 die system in which other players can
          reroll your dice to help or hinder you, and if you roll three 6&apos;s, you
          accidentally summon the devil itself.
        </Paragraph>
        <H3>Feedback</H3>
        <Paragraph>
          Have thoughts? A tale of chaos at the manor? A deal with the devil to
          offer me? Email me at{' '}
          <A href="mailto:sam@samvbeckmann.com">sam@samvbeckmann.com</A>.
        </Paragraph>
      </div>
    </BasicLayout>
  );
}
